import {useEffect} from 'react';

import {useAppDispatch, useAppSelector} from 'store';
import {fetchZones, selectZones, selectZoneStatus} from 'features/zoneSlice';

import type {Zone} from 'types';
import {nameSort} from 'utils';

export function useZones(): Zone[] {
    const dispatch = useAppDispatch();
    const zones = useAppSelector(selectZones);
    const zoneStatus = useAppSelector(selectZoneStatus);

    useEffect(() => {
        if (zoneStatus === 'idle') {
            dispatch(fetchZones() as any);
        }
    }, [zoneStatus, dispatch]);

    return [...zones].sort(nameSort);
}
