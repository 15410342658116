import {useState} from 'react';
import {PayloadAction} from '@reduxjs/toolkit';
import {message} from 'antd';

import type {PlatformError, Role, Team, PersonRef} from 'types';
import {AppDispatch, useAppDispatch} from 'store';

import {map_} from 'utils';
import {useCurrentUser} from 'hooks/useCurrentUser';
import {useTemplates} from 'hooks/useTemplates';
import {useTeams} from 'hooks/useTeams';

import CreateSteps from 'components/steps/_common/CreateSteps';
import NameStep from 'components/steps/_common/NameStep';
import TemplateStep from 'components/steps/_common/TemplateStep';
import TeamStep from 'components/steps/_common/TeamStep';

import {createRole} from 'features/roleSlice';

const CreateRoleSteps = (props: {
    team?: Team,
    onSuccess: (role: Role) => void,
    onCancel: () => void
}) => {
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch: AppDispatch = useAppDispatch();

    const currentUser = useCurrentUser();
    const [role, setRole] = useState({} as Role);

    const templates = map_(useTemplates());
    const teams = map_(useTeams());

    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setRole({} as Role);
        }, 0);
    }

    async function onCreate() {
        const newRole = {
            ...role,
            owners: [{id: currentUser?.id, type: 'person'} as PersonRef],
        };
        if (props.team) {
            newRole.team = {id: props.team.id, type: 'team'}
        }

        const res = await dispatch(createRole(newRole as Role) as any) as PayloadAction<Role | PlatformError, any, any, string>;
        if (res.error) {
            return messageApi.error((res.payload as PlatformError).message);
        }
        props.onSuccess(res.payload as Role);
    }

    const steps = [
        {
            title: 'Role Template',
            content:
                <TemplateStep
                    objectType={'role'}
                    // templateId={role.templateId || ''}
                    setTemplate={(templateId: string) => {
                        const newRole = {...role, templateId};
                        if (!newRole.name && props.team) {
                            newRole.name = `${props.team.name} : ${templates[templateId].name}`;
                        }
                        setRole(newRole);
                    }}
                />,
            valid: () => true,
        }
    ];

    if (!props.team) {
        steps.push(
            {
                title: 'Team',
                content:
                    <TeamStep
                        title="What team will have this role?"
                        teamId={role.team.id || ''}
                        setTeamId={teamId => {
                            const newRole = {...role, contributesTo: {_id: teamId, _type: 'team'}} as Role;
                            if (!newRole.name) {
                                newRole.name = `${teams[teamId]?.name || teamId} : ROLE HERE`;
                            }
                            setRole(newRole);
                        }}
                    />,
                valid: () => !!role.team.id
            }
        )
    }

    steps.push(
        {
            title: 'Name',
            content:
                <NameStep
                    title="What should this role be called?"
                    name={role.name || ''}
                    setName={name => {
                        setRole({...role, name});
                    }}
                />,
            valid: () => !!role.name,
        }
    )

    return (
        <>
            <CreateSteps
                steps={steps}
                onCreate={onCreate}
                onCancel={onCancel}
            />
            {contextHolder}
        </>
    )
}

export default CreateRoleSteps;
