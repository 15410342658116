import {Form, Input} from 'antd';

const TextFieldStep = (props: {
    title: string,
    required: boolean
    value: string,
    setValue: (value: string) => void,
}) => {
    const [form] = Form.useForm<{ value: string }>();
    Form.useWatch(values => {
        if (values.value !== undefined) {
            props.setValue(values.value);
        }
    }, form);

    return (
        <Form
            name="textFieldStep"
            form={form}
            style={{maxWidth: 600}}
            initialValues={{value: props.value}}
            layout="vertical"
            autoComplete="off"
        >
            <Form.Item<{value: string}>
                label={props.title}
                name="value"

                rules={[{required: props.required, message: "Please enter a value."}]}
            >
                <Input/>
            </Form.Item>
        </Form>)
}


export default TextFieldStep;
