import React, {useState} from 'react';
import {Button} from 'antd';

import CreateModal from './CreateModal';

const CreateButton = (props: {
    title: string,
    children: (onSuccess: () => void, onClose: () => void) => React.ReactNode,
    onSuccess?: () => void;
}) => {
    const [openModal, setOpenModal] = useState(false);

    async function onSuccess() {
        setOpenModal(false);
        props.onSuccess?.();
    }

    function onClose() {
        setOpenModal(false);
    }

    return (
        <>
            <Button
                onClick={() => setOpenModal(true)}
            >
                {props.title}
            </Button>
            <CreateModal
                title={props.title}
                open={openModal}
                onCancel={onClose}
            >
                {props.children(onSuccess, onClose)}
            </CreateModal>
        </>
    );
}

export default CreateButton;
