import React, {useState} from 'react';
import {Button, Flex, Steps} from 'antd';

import styles from './CreateSteps.module.scss';


export type CreateStepProps = {
    title: string,
    content: React.ReactNode,
    valid: () => boolean,
}

const CreateSteps = (props: {
    steps: CreateStepProps[],
    onCreate: () => void,
    onCancel: () => void,
    actionText?: string
}) => {
    const [current, setCurrent] = useState(0);
    const items = props.steps.map((item) => ({key: item.title, title: item.title}));

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            /*
             * Run this on the next tick so the user doesn't see the content reset
             * when the modal is transitioning closed.
             */
            setCurrent(0);
        }, 0);
    }

    return (
        <Flex className={styles.steps}>
            {props.steps && props.steps.length > 1 ?
                <Steps
                    size={'small'}
                    direction={'vertical'}
                    current={current}
                    items={items}
                    progressDot
                /> : null
            }

            <div>
                <div style={{marginTop: '1rem'}}>
                    {props.steps[current].content}
                </div>

                <Flex style={{marginTop: 24}} gap={'8px'}>
                    {current < props.steps.length - 1 && (
                        <Button
                            type="primary"
                            onClick={() => next()}
                            disabled={!props.steps[current].valid()}
                        >
                            Next
                        </Button>
                    )}
                    {current === props.steps.length - 1 && (
                        <Button
                            type="primary"
                            onClick={props.onCreate}
                            disabled={!props.steps[current].valid()}
                        >
                            {props.actionText ? props.actionText : 'Create'}
                        </Button>
                    )}
                    {current > 0 && (
                        <Button onClick={() => prev()}>
                            Previous
                        </Button>
                    )}
                    <Button onClick={onCancel}>
                        Cancel
                    </Button>
                </Flex>
            </div>
        </Flex>
    );
}

export default CreateSteps;
