import {Form, Select} from 'antd';

const MultiSelectFieldStep = (props: {
    title: string,
    required: boolean
    values: string[],
    setValues: (values: string[]) => void,
    options: string[]
}) => {
    const [form] = Form.useForm<{ values: string[] }>();
    Form.useWatch(values => {
        if (values.values !== undefined) {
            props.setValues(values.values);
        }
    }, form);

    const options = props.options.map(option => {
        return {label: option, value: option};
    });

    return (
        <Form
            name="multiSelectFieldStep"
            form={form}
            style={{maxWidth: 600}}
            initialValues={{values: props.values}}
            layout="vertical"
            autoComplete="off"
        >
            <Form.Item<{ values: string }>
                label={props.title}
                name="values"
                rules={[{required: props.required, message: 'Select one or more values.'}]}
            >
                <Select mode={'multiple'} options={options} />
            </Form.Item>
        </Form>)
}

export default MultiSelectFieldStep;
