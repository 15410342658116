import {useState} from 'react';

import {Button, Checkbox, Flex, Form, Input, Select} from 'antd';
import type {Field, MultiSelectField, OneToManyField, OneToOneField, SingleSelectField} from 'types';

import SelectField from './SelectField';
import RelationshipField from './RelationshipField';

const FieldForm = (props: {
    field?: Field,
    onFinish: (field: Field) => void,
}) => {
    const [field, setField] = useState<Field>(
        props.field ? props.field : {} as Field
    );

    function onFinish(values: Field) {
        const newField = Object.assign({}, field, values);
        // noinspection PointlessBooleanExpressionJS
        newField.required = !!values.required;
        props.onFinish(newField);
    }

    return (
        <Form
            name="field"
            style={{maxWidth: 600}}
            initialValues={props.field}
            onFinish={onFinish}
            autoComplete="off"
        >
            <Form.Item<Field>
                label="Name"
                name="name"
                rules={[{required: true, message: 'Please input a name!'}]}
            >
                <Input value={field.name} onChange={e => setField({...field, name: e.target.value})}/>
            </Form.Item>

            <Form.Item<Field>
                label="Field Type"
                name="type"
                rules={[{required: true, message: 'Select a field type.'}]}
            >
                <Select
                    options={[
                        {value: 'text', label: 'Text'},
                        {value: 'single-select', label: 'Single Select'},
                        {value: 'multi-select', label: 'Multi Select'},
                        {value: 'one-to-one', label: 'One-To-One Relationship'},
                        {value: 'one-to-many', label: 'One-To-Many Relationship'},
                    ]}
                    onChange={type => setField({...field, type})}
                />
            </Form.Item>

            <Form.Item<Field>
                name="required"
                valuePropName="checked"
            >
                <Checkbox>Required</Checkbox>
            </Form.Item>

            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
            >
                {field.type === 'single-select' &&
                    <SelectField field={field as SingleSelectField} setField={setField}/>}
                {field.type === 'multi-select' &&
                    <SelectField field={field as MultiSelectField} setField={setField}/>}
                {field.type === 'one-to-one' &&
                    <RelationshipField field={field as OneToOneField} setField={setField}/>}
                {field.type === 'one-to-many' &&
                    <RelationshipField field={field as OneToManyField} setField={setField}/>}
            </Form.Item>

            <Flex style={{marginTop: 24, justifyContent: 'end'}} gap={'8px'}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Flex>
        </Form>
    );
}

export default FieldForm;
