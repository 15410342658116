import React from 'react';
import {useSpring, animated} from '@react-spring/web';

const AnimatedText = (
    {x, y, ...props}: React.SVGAttributes<SVGTextElement>
) => {
    const animatedProps = useSpring({
        x,
        y,
    });
    return (
        <animated.text
            {...props}
            x={animatedProps.x as any}
            y={animatedProps.y as any}
        />
    );
};

export default AnimatedText;
