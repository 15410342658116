import {useState} from 'react';
// import {message} from 'antd';

import {Role, RoleAssignment} from 'types';

// import {AppDispatch, useAppDispatch} from 'store';

import CreateSteps from 'components/steps/_common/CreateSteps';
import PersonStep from 'components/steps/_common/PersonStep';


const AssignUserToRoleSteps = (props: {
    role: Role,
    onSuccess: (roleAssignment: RoleAssignment) => void,
    onCancel: () => void,
}) => {
    // const [messageApi, contextHolder] = message.useMessage();

    const [userId, setUserId] = useState<string>();
    // const dispatch: AppDispatch = useAppDispatch();

    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setUserId(undefined);
        }, 0);
    }

    async function onCreate() {
        /*
        const roleAssignment = {
            status: 'active',
            assignee: {_id: userId, _type: 'user'},
            role: {id: props.role.id, type: 'role'}
        };

        const res = await dispatch(createRoleAssignment(roleAssignment as RoleAssignment) as any) as PayloadAction<RoleAssignment|PlatformError, any, any, string>;
        if (res.error) {
            return messageApi.error((res.payload as PlatformError).message);
        }
        props.onSuccess(res.payload as RoleAssignment);
         */
    }

    const steps = [
        {
            title: 'User',
            content:
                <PersonStep
                    title="What user should have this role?"
                    userId={userId || ''}
                    setUserId={setUserId}
            />,
            valid: () => !!userId,
        }];

    return (
        <>
            <CreateSteps
                steps={steps}
                onCreate={onCreate}
                onCancel={onCancel}
                actionText={'Assign'}
            />
        </>
    )
}

export default AssignUserToRoleSteps;

/* {contextHolder} */
