import React from 'react';
import {Select} from 'antd';

import {selectOptions} from 'utils';
import {useTeams} from 'hooks/useTeams';

const TeamStep = (props: {
    title: string,
    teamId: string,
    setTeamId: (teamId: string) => void;
}) => {
    const teams = useTeams();
    const options = selectOptions(teams);

    return (
        <label className={'viggo-required'}>
            {props.title}
            <Select
                style={{width: '100%', marginTop: '8px'}}  // FIXME padding should come from the label
                placeholder="Please select"
                defaultValue={props.teamId}
                onChange={props.setTeamId}
                options={options}
            />
        </label>
    )
}

export default TeamStep;
