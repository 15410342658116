import React from 'react';

import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import {Table, Typography} from 'antd';
import type { TableColumnsType } from 'antd';
import type {Field, Keyed} from 'types';

type DataType = Keyed<Field>;

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

const Row: React.FC<Readonly<RowProps>> = (props) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: props['data-row-key'],
    });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        cursor: 'move',
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

function toDataSource(fields: Field[]) {
    return fields.map((field, index) => {
        (field as DataType).key = index.toString();
        return field as DataType;
    });
}

const FieldsTable = (props: {
    fields: Field[],
    setFields: (fields: Field[]) => void
}) => {
    const dataSource = toDataSource(props.fields);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, {key}) => (
                <span>
                    <Typography.Link type={'danger'} onClick={() => handleDelete(key)}>
                        Delete
                    </Typography.Link>
                </span>
            )
        },
    ];

    const onDragEnd = ({active, over}: DragEndEvent) => {
        if (active.id !== over?.id) {
            const activeIndex = dataSource.findIndex((i: DataType) => i.key === active.id);
            const overIndex = dataSource.findIndex((i: DataType) => i.key === over?.id);
            props.setFields(arrayMove(dataSource, activeIndex, overIndex));
        }
    };

    const handleDelete = (key: string) => {
        const newData = dataSource.filter((field: Keyed<Field>) => field.key !== key);
        props.setFields(newData);
    }

    return (
        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
                // rowKey array
                items={dataSource.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
            >
                <Table<Keyed<Field>>
                    components={{
                        body: { row: Row },
                    }}
                    rowKey="key"
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    showHeader={false}
                />
            </SortableContext>
        </DndContext>
    );
};

export default FieldsTable;
