import React from 'react';
import {Link} from 'react-router-dom';

import {Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import type {Template} from 'types';
import {asDataSource, map} from 'utils';
import {useTemplates} from 'hooks/useTemplates';
import CreateTemplateButton from './CreateTemplateButton';

function createColumns(templates: Record<string, Template>) {
    const columns: ColumnsType = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, {id}) => <Link to={`/settings/templates/${id}`}>{text}</Link>
        },
        {
            title: 'Type',
            dataIndex: 'objectType',
            key: 'objectType',
            render: text => text
        }
    ];
    return columns;
}

const TemplateListPage = () => {
    const templates = useTemplates();
    return (
        <>
            <Table
                columns={createColumns(map<Template>(templates))}
                dataSource={asDataSource(templates)}
                pagination={false}
            />
            <CreateTemplateButton
                style={{margin: '1em 0'}}
            />
        </>
    );
}

export default TemplateListPage;
