import React from 'react';
import {useParams} from 'react-router-dom';

import {Card, Descriptions, Flex} from 'antd';

import type {Person} from 'types';
import Object from 'components/Object';
import MainContent from 'components/layout/MainContent';

import {usePerson} from 'hooks/usePeople';

function items(user: Person) {
    return [
        {
            key: 'name',
            label: 'Name',
            children: user.name,
            span: 4
        },
        {
            key: 'city',
            label: 'City',
            children: user.city,
            span: 4
        },
        {
            key: 'state',
            label: 'State',
            children: user.state,
            span: 2
        },
        {
            key: 'countryCode',
            label: 'Country',
            children: user.countryCode,
            span: 2
        },
        {
            key: 'timezone',
            label: 'Timezone',
            children: user.timezone,
            span: 4
        },
        {
            key: 'status',
            label: 'Status',
            children: user.status,
            span: 4
        }
    ]
}


const PersonDetailPage = () => {
    let { personId } = useParams();
    const person = usePerson(personId);

    if (!person) {
        return null;
    }

    return (
        <MainContent>
            <Card>
                {person._picture_medium ?
                    <Flex justify={'center'}>
                        <img src={person._picture_medium} alt={person.name}/>
                    </Flex> : null
                }
                <Descriptions
                    title="User Info"
                    items={items(person)}
                    column={4}
                />
            </Card>
            <Object object={person}/>
        </MainContent>
    );
}

export default PersonDetailPage;

/*
<UserRolesTable user={person}/>
<Card>
    <Typography.Title level={2}>Goals</Typography.Title>
    <UserGoalsTable user={person} />
</Card>
<Card>
    <Typography.Title level={2}>Agreements</Typography.Title>
    <UserAgreementsTable user={person} />
</Card>
*/
