const AdminPage = () => {
    return (
        <div>
            <p>This page is for system admins only.   It will mostly be for creating/managing organizations.</p>
            <p>{process.env['REACT_APP_API_URL']}</p>
        </div>
    );
}

export default AdminPage;
