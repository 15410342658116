import React from 'react';
import {useParams} from 'react-router-dom';

import {Card, Descriptions, DescriptionsProps} from 'antd';

import type {Template} from 'types';
import {map} from 'utils';
import Object from 'components/Object';
import MainContent from 'components/layout/MainContent';

import {useTemplates} from 'hooks/useTemplates';
import DeleteTemplateButton from './DeleteTemplateButton';


function items(template: Template) {
    const items: DescriptionsProps['items'] = [
        {
            key: 'name',
            label: 'Name',
            children: template.name,
            span: 4
        }
    ];
    return items;
}


const TemplateDetailPage = () => {
    let { templateId } = useParams();
    const templates = map(useTemplates());
    const template = templateId ? templates[templateId] : undefined;

    if (!template) {
        return null;
    }

    return (
        <MainContent>
            <Card>
                <Descriptions
                    title="Template Info"
                    items={items(template)}
                    column={4}
                />
                {!!template.description ? template.description : null}
            </Card>
            <DeleteTemplateButton templateId={template.id} />
            <Object object={template}/>
        </MainContent>
    );
}

export default TemplateDetailPage;
