import {useEffect} from 'react';

import {useAppDispatch, useAppSelector} from 'store';

import type {Template} from 'types';
import {nameSort} from 'utils';
import {fetchTemplates, selectTemplates, selectTemplateStatus} from 'features/templateSlice';

export function useTemplates(objectType?: Template['objectType']): Template[] {
    const dispatch = useAppDispatch();
    let templates = useAppSelector(selectTemplates) || [];
    const templateStatus = useAppSelector(selectTemplateStatus);

    useEffect(() => {
        if (templateStatus === 'idle') {
            dispatch(fetchTemplates());
        }
    }, [templateStatus, dispatch]);

    if (objectType) {
        templates = templates.filter(template => template.objectType === objectType)
    }

    return [...templates].sort(nameSort);
}

export function useTemplate(id: string|undefined, objectType: string): Template|undefined {
    const templates = useTemplates();
    return id ? templates.find((template) => (template.id === id && template.objectType === objectType)) : undefined;
}

export function useTemplateByName(name: string, objectType: string) {
    return useTemplates().find(template => {
        return template.objectType === objectType && template.name.toLowerCase() === name.toLowerCase();
    });
}

