import {configureStore, createAction} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';

import themeModeSlice from 'features/themeModeSlice';
import currentSlice from 'features/currentUserSlice';
import roleSlice from 'features/roleSlice';
import roleAssignmentSlice from 'features/roleAssignmentSlice';
import teamSlice from 'features/teamSlice';
import templateSlice from 'features/templateSlice';
import agreementSlice from 'features/agreementSlice';
import goalSlice from 'features/goalSlice';
import peopleSlice from 'features/personSlice';
import orgSlice from 'features/orgSlice';
import zoneSlice from 'features/zoneSlice';

export const LOGOUT_ACTION = createAction('logout')
export const CLEAR_ORG_ACTION = createAction('clear');

const store = configureStore({
    reducer: {
        themeMode: themeModeSlice,
        current: currentSlice,
        org: orgSlice,

        agreements: agreementSlice,
        goals: goalSlice,
        roles: roleSlice,
        roleAssignments: roleAssignmentSlice,
        teams: teamSlice,
        templates: templateSlice,
        people: peopleSlice,
        zones: zoneSlice,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export default store;
