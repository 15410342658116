import type {Person} from 'types';
import {Avatar, Flex} from 'antd';
import React from 'react';

const RoleUserDetail = (props: {
    user: Person|undefined
}) => {
    if (!props.user) {
        return <div style={{margin:'2em 0', textAlign:'center'}}>This role is unfilled.</div>
    }

    return (
        <div>
            <Flex style={{margin: '1em 0'}} vertical justify={'center'} align={'center'}>
                {props.user._picture_medium ? <Avatar size={128} src={props.user._picture_large} alt={props.user.name} /> : null}
                <span style={{margin: '1em 0'}}>{props.user.name}</span>
            </Flex>
        </div>
    );
}

export default RoleUserDetail;
