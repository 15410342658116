import {Card, Typography} from 'antd';
import {Organization} from 'types';

import staticImage from 'assets/viggo-icon.svg';

const OrgCard = (props: {
    org: Organization
    onClick?: (org: Organization) => void;
    
}) => {
    return (
        <Card className='org-card' onClick={() => props.onClick?.(props.org)}>
            <div className='org-card-icon'>
                <img src={staticImage} alt={'Viggo'} />
            </div>
            <Typography.Title level={3}>{props.org.name}</Typography.Title>
        </Card>
    );
}

export default OrgCard;
