import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {Team, SliceStatus, Organization, ErrorDetail} from 'types';
import {CLEAR_ORG_ACTION, LOGOUT_ACTION, RootState} from 'store';
import apiClient from 'api';


type TeamsState = {
    entities: Team[],
    status: SliceStatus,
    error: string,
};

export const fetchTeams = createAsyncThunk<Team[]>('teams/fetch', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    return await apiClient.get(`/org/${state.org.value.id}/teams`);
});

export const deleteTeam = createAsyncThunk<Team, string, {
    rejectValue: ErrorDetail,
}>('team/delete', async (userId, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.delete<Team>(`/org/${state.org.value.id}/teams/${userId}`);
    } catch (e) {
        return thunkAPI.rejectWithValue((await (e as Response).json()) as ErrorDetail);
    }
});

export const createTeam = createAsyncThunk<Team, Team, {
    rejectValue: ErrorDetail,
}>('team/create', async (team, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.post(`/org/${state.org.value.id}/teams`, team);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

const INITIAL_STATE = {
    entities: [],
    status: 'idle' as TeamsState['status'],
    error: ''
};

// noinspection JSUnusedGlobalSymbols
const teamSlice = createSlice({
    name: 'teams',
    initialState: INITIAL_STATE satisfies TeamsState as TeamsState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchTeams.pending, (state: TeamsState) => {
                state.status = 'pending';
            })
            .addCase(fetchTeams.fulfilled, (state: TeamsState, action: PayloadAction<Team[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as Team[];
            })
            .addCase(fetchTeams.rejected, (state: TeamsState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.log(action);
            })
            .addCase(createTeam.fulfilled, (state: TeamsState, action: PayloadAction<Team|ErrorDetail>) => {
                state.entities.push(action.payload as Team);
            })
            .addCase(deleteTeam.fulfilled, (state: TeamsState, action) => {
                state.entities = state.entities.filter((user: Team) => user.id !== action.payload.id);
            })
            .addCase(CLEAR_ORG_ACTION, (state: TeamsState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(LOGOUT_ACTION, (state: TeamsState) => {
                Object.assign(state, INITIAL_STATE);
            })
    }
});

export const selectTeams = (state: RootState) => state.teams.entities;
export const selectTeamStatus = (state: RootState) => state.teams.status;

export default teamSlice.reducer;
