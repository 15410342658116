import {Outlet} from 'react-router-dom';
import {Layout} from 'antd';
import type {BreadcrumbItemType, BreadcrumbSeparatorType} from 'antd/es/breadcrumb/Breadcrumb';

import MainSider from 'components/sider/MainSider';
import MainHeader from 'components/header/MainHeader';

import styles from './MainLayout.module.scss';


const MainLayout = (props: {
    pageTitle: string|undefined,
    breadcrumbs:  Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] | null | undefined,
}) => {
    return (
        <Layout className={styles.ggwrapper}>
            <MainSider/>
            <Layout className='ggmain'>
                <MainHeader pageTitle={props.pageTitle} breadcrumbs={props.breadcrumbs} />
                <Layout.Content className='ggcontent'>
                    <Outlet/>
                </Layout.Content>
            </Layout>
        </Layout>
    );
}

export default MainLayout;
