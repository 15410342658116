import {useState} from 'react';
import {PayloadAction} from '@reduxjs/toolkit';
import {message} from 'antd';

import {PlatformError, Team, PersonRef} from 'types';
import {AppDispatch, useAppDispatch} from 'store';

import {useCurrentUser} from 'hooks/useCurrentUser';

import CreateSteps from 'components/steps/_common/CreateSteps';
import NameStep from 'components/steps/_common/NameStep';
import ParentZoneStep from 'components/steps/_common/ParentZoneStep';
import {createTeam} from 'features/teamSlice';

const CreateTeamSteps = (props: {
    zone?: Team,
    onSuccess: (team: Team) => void,
    onCancel: () => void,
}) => {
    const [messageApi, contextHolder] = message.useMessage();

    const currentUser = useCurrentUser();
    const [team, setTeam] = useState({} as Team);
    const dispatch: AppDispatch = useAppDispatch();

    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setTeam({} as Team);
        }, 0);
    }

    async function onCreate() {
        const newTeam = {
            ...team,
            owners: [{id: currentUser?.id, type: 'person'} as PersonRef],
        };
        if (props.zone) {
            newTeam.zone = {id: props.zone.id, type: 'zone'}
        }
        const res = await dispatch(createTeam(newTeam as Team) as any) as PayloadAction<Team | PlatformError, any, any, string>;
        if (res.error) {
            return messageApi.error((res.payload as PlatformError).message);
        }
        props.onSuccess(res.payload as Team);
    }

    const steps = [
        {
            title: 'Name',
            content:
                <NameStep
                    title="What should this team be called?"
                    name={team.name || ''}
                    setName={name => {
                        setTeam({...team, name});
                    }}
                />,
            valid: () => !!team.name,
        },
        /*
        {
            title: 'Team Template',
            content:
                <TemplateStep
                    templateType={'team'}
                    templateId={team.templateId}
                    setTemplate={(templateId: string) => {
                        setTeam({...team, templateId});
                    }}
                />,
            valid: () => !!team.templateId,
        },
         */
    ];

    if (!props.zone) {
        steps.push(
            {
                title: 'Parent Zone',
                content:
                    <ParentZoneStep
                        parentZone={team.zone?.id || ''}
                        setParentZone={(zone_id: string) => {
                            setTeam({
                                ...team,
                                zone: {id: zone_id, type: 'zone'}
                            });
                        }}
                    />,
                valid: () => !!team.zone.id,
            },
        )
    }

    return (
        <>
            <CreateSteps
                steps={steps}
                onCreate={onCreate}
                onCancel={onCancel}
            />
            {contextHolder}
        </>
    )
}

export default CreateTeamSteps;
