import React from 'react';
import {Select} from 'antd';

import {useZones} from 'hooks/useZones';
import {selectOptions} from 'utils';

const ParentZoneStep = (props: {
    title?: string,
    parentZone: string,
    setParentZone: (parentZone: string) => void;
    required?: boolean
}) => {
    const zones = useZones();
    const options = selectOptions(zones);
    const title = props.title ? props.title : 'What is the parent zone?';

    const className = !!props.required ? 'viggo-required' : undefined;

    return (
        <label className={className}>
            {title}
            <Select
                style={{width: '100%'}}
                placeholder="Please select"
                defaultValue={props.parentZone}
                onChange={props.setParentZone}
                options={options}
            />
        </label>
    )
}

export default ParentZoneStep;
