import {Form, Select} from 'antd';
import {ObjectRef} from 'types';

import {useObjects} from 'hooks/useObjects';
import {buildObjectOptions} from './OneToOneFieldStep';

function initialValues(refs: ObjectRef[]|undefined) {
    if (refs) {
        return refs.map(ref => JSON.stringify(ref))
    }
    return []
}

const OneToManyFieldStep = (props: {
    title: string,
    required: boolean
    objectRefs: ObjectRef[]|undefined,
    setObjectRefs: (values: ObjectRef[]) => void,
    allowed: ObjectRef['type'][]
}) => {
    const objs = useObjects(props.allowed);

    const [form] = Form.useForm<{ values: string[] }>();
    Form.useWatch(values => {
        if (values.values !== undefined) {
            props.setObjectRefs(values.values.map(value => JSON.parse(value)));
        }
    }, form);

    return (
        <Form
            name="textFieldStep"
            form={form}
            style={{maxWidth: 600}}
            initialValues={{values: initialValues(props.objectRefs)}}
            layout="vertical"
            autoComplete="off"
        >
            <Form.Item<{ values: string }>
                label={props.title}
                name={'values'}
                rules={[{required: props.required, message: 'Select a type.'}]}
            >
                <Select mode={'multiple'} options={buildObjectOptions(objs)} />
            </Form.Item>
        </Form>)
}

export default OneToManyFieldStep;
