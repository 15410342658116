import {useEffect} from 'react';

import type {Role} from 'types';
import {useAppDispatch, useAppSelector} from 'store';
import {fetchRoles, selectRoles, selectRoleStatus} from 'features/roleSlice';
import {nameSort} from 'utils';

export function useRoles(): Role[] {
    const dispatch = useAppDispatch();
    const roles = useAppSelector(selectRoles);
    const roleStatus = useAppSelector(selectRoleStatus);

    useEffect(() => {
        if (roleStatus === 'idle') {
            dispatch(fetchRoles());
        }
    }, [roleStatus, dispatch]);

    return [...roles].sort(nameSort);
}

export function useRole(roleId: string|undefined) {
    const roles = useRoles();
    return roles.find(role => role.id === roleId);
}
