import {OneToOneField, OneToManyField, ObjectType} from 'types';
import {Select} from 'antd';

const RelationshipField = (props: {
    field: OneToOneField|OneToManyField,
    setField: (field: OneToOneField|OneToManyField) => void,
}) => {
    function onChange(allowed: string[]) {
        props.setField({...props.field, allowed: allowed as ObjectType[]});
    }

    return (
        <label>
            Object types that may be referenced.
            <Select style={{width:'100%'}} mode="multiple" onChange={onChange} options={[
                //{label: 'Agreement', value: 'agreement'},
                //{label: 'Goal', value: 'goal'},
                {label: 'Person', value: 'person'},
                {label: 'Role', value: 'role'},
                {label: 'Team', value: 'team'},
                {label: 'Zone', value: 'zone'}
            ]} />
        </label>
    );
}

export default RelationshipField;
