import {Collapse} from 'antd';

const Object = (props: {
    label?: string,
    object: any
    style?: React.CSSProperties
}) => {
    const text = (
        <pre>
            {JSON.stringify(props.object, null, 4)}
        </pre>
    );

    const items = [
        {
            key: 'object',
            label: props.label ? props.label : 'Object',
            children: text
        }
    ]

    return (
        <Collapse style={props.style} items={items} bordered={false} />
    );
}

export default Object;
