import CreateRoleSteps from 'components/steps/roles/CreateRoleSteps';
import CreateModal from 'components/steps/_common/CreateModal';

import type {Graph} from './GraphComponent';
import {Team, Zone, Role} from 'types';
import CreateZoneSteps from 'components/steps/zones/CreateZoneSteps';
import CreateTeamSteps from 'components/steps/teams/CreateTeamSteps';
import AssignUserToRoleSteps from 'components/steps/roles/AssignUserToRoleSteps';

export type NodeAction = {
    action: string,
    node: Graph,
}

const NodeActions = (props: {
    action: NodeAction|undefined
    onClose: () => void;
}) => {
    return (
        <div>
            <CreateModal
                title={`Add role to ${props.action?.node.obj.name}`}
                open={props.action?.action === 'add-role'}
                onCancel={props.onClose}
            >
                <CreateRoleSteps
                    team={props.action?.node.obj as Team|undefined}
                    onSuccess={props.onClose}
                    onCancel={props.onClose}
                />
            </CreateModal>
            <CreateModal
                title={`Add zone to ${props.action?.node.obj.name}`}
                open={props.action?.action === 'add-zone'}
                onCancel={props.onClose}
            >
                <CreateZoneSteps
                    zone={props.action?.node.obj as Zone|undefined}
                    onSuccess={props.onClose}
                    onCancel={props.onClose}
                />
            </CreateModal>
            <CreateModal
                title={`Add team to ${props.action?.node.obj.name}`}
                open={props.action?.action === 'add-team'}
                onCancel={props.onClose}
            >
                <CreateTeamSteps
                    zone={props.action?.node.obj as Team|undefined}
                    onSuccess={props.onClose}
                    onCancel={props.onClose}
                />
            </CreateModal>
            <CreateModal
                title={`Assign User to ${props.action?.node.obj.name}`}
                open={props.action?.action === 'assign-user-to-role'}
                onCancel={props.onClose}
            >
                <AssignUserToRoleSteps
                    role={props.action?.node.obj as Role}
                    onSuccess={props.onClose}
                    onCancel={props.onClose}
                />
            </CreateModal>
        </div>
    );
}

export default NodeActions;
