import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {App, Form, Input, Button, Select, Flex} from 'antd';

import styles from './CreateTemplatePage.module.scss';
import type {ErrorDetail, ErrorDetailDispatch, Template, Field} from 'types';
import {AppDispatch, useAppDispatch} from 'store';
import {createTemplate} from 'features/templateSlice';
import AddField from './AddField';
import FieldsTable from './FieldsTable';


type FormFieldsType = {
    name?: string,
    description?: string,
    objectType?: Template['objectType']
};

const CreateTemplatePage = () => {
    const navigate = useNavigate();
    const {message} = App.useApp();

    const dispatch: AppDispatch = useAppDispatch();
    const [fields, setFields] = useState<Field[]>([]);

    async function onFinish(values: FormFieldsType) {
        const newTemplate = {...values, fields: fields as Field[]} as Template;

        const res = await dispatch(createTemplate(newTemplate));
        if ((res as ErrorDetailDispatch<Template>).error) {
            return message.error((res.payload as ErrorDetail).detail.toString());
        }
        navigate('/settings');
    }

    function onAdd(field: Field) {
        const newFields = [...fields, field];
        setFields(newFields);
    }

    return (
        <div className={styles.page}>
            <p>A template allows you to define the information collected for objects in this organization.</p>
            <Form
                name="template"
                style={{maxWidth: 480}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item<FormFieldsType>
                    label="Name"
                    name="name"
                    rules={[{required: true, message: 'Please input a name!'}]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FormFieldsType>
                    label="Description"
                    name="description"
                    help={'Markdown is allowed.'}
                >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item<FormFieldsType>
                    label="Object"
                    name="objectType"
                    rules={[{required: true, message: 'Select a template type.'}]}
                >
                    <Select
                        options={[
                            {value: 'role', label: 'Role'},
                            {value: 'team', label: 'Team'},
                            {value: 'zone', label: 'Zone'},
                        ]}
                    />
                </Form.Item>

                <FieldsTable fields={fields} setFields={setFields}/>
                <AddField onAdd={onAdd}/>

                <Flex justify={'end'}>
                    <Form.Item>
                        <Button type="default" style={{marginRight: '8px'}} onClick={() => navigate('/settings')}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Flex>
            </Form>
        </div>
    );
}

export default CreateTemplatePage;
