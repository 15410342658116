import React from 'react';
import {Link} from 'react-router-dom';

import {Flex, Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import type {Zone} from 'types';
import {asDataSource, map} from 'utils';
import {useZones} from 'hooks/useZones';

function createColumns(zones: Record<string, Zone>) {
    const columns: ColumnsType = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, {id}) => <Link to={`/zones/${id}`}>{text}</Link>
        },
        /* FIXME: make this template type.
        {
            title: 'Type',
            dataIndex: 'zoneType',
            key: 'zoneType',
            render: text => zoneTypeDisplay(text)
        },
         */
        {
            title: 'Parent Zone',
            dataIndex: 'parentZone',
            key: 'parentZone',
            render: (text, {parentZone}) => {
                if (!parentZone) {
                    return null;
                }
                const zone = zones[parentZone.id];
                return (
                    <Flex align={'center'} gap={'1rem'}>
                        <Link to={`/zones/${parentZone.id}`}>{zone.name}</Link>
                    </Flex>
                )
            }
        }
    ];
    return columns;
}

const ZonesListPage = () => {
    const zones = useZones();
    return (
        <Table
            columns={createColumns(map<Zone>(zones))}
            dataSource={asDataSource(zones)}
            pagination={false}
        />
    );
}

export default ZonesListPage;
