import {Descriptions} from 'antd';

import type {Role, Person} from 'types';
import RoleUserDetail from './RoleUserDetail';
import Object from '../../components/Object';
import React from 'react';


const RoleDetails = (props: {
    role: Role|undefined,
    user: Person|undefined,
}) => {
    //const template = useTemplate(props.role?.templateId || undefined, 'role');

    if (!props.role) {
        return null;
    }

    //const templateName = template ? template.name : 'UNKNOWN'

    const items = [
        {
            key: 'name',
            label: 'Name',
            children: props.role.name
        }
    ];

    return (
        <>
            <RoleUserDetail user={props.user} />
            <Descriptions column={1} bordered={true} layout={'vertical'} items={items} />
            <Object style={{margin: '1em 0'}} object={props.role}/>
        </>
    );
}

export default RoleDetails;

/* <MarkdownDiv markdown={props.role.description} /> */
