import React from 'react';
import * as d3 from 'd3';

import type {Graph} from './GraphComponent';
import AnimatedCircle from './AnimatedCircle';


const ZoneNode = (props: {
    node: d3.HierarchyCircularNode<Graph>,
    cx: number,
    cy: number,
    r: number,
    onClick: (node: d3.HierarchyCircularNode<Graph>) => void,
    onAction?: (action: string, node: d3.HierarchyCircularNode<Graph>) => void
}) => {

    function onClick(event: React.MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        props.onClick(props.node)
    }

    return (
        <AnimatedCircle
            cx={props.cx}
            cy={props.cy}
            r={props.r}
            onContextMenu={event => event.stopPropagation()}
            stroke={'#bd016e'}
            fill={'#bd016e'}
            onClick={onClick}
        />
    );
}

export default ZoneNode;
