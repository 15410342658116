import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

import type {Organization} from 'types';
import {CLEAR_ORG_ACTION, RootState} from 'store';
import {getOrgFromSession, setOrgInSession} from 'utils';

const orgSlice = createSlice({
    name: 'org',
    initialState: {
        value: getOrgFromSession() as Organization | null
    },
    reducers: {
        setOrg: (state, action: PayloadAction<Organization|null>) => {
            state.value = action.payload;
            setOrgInSession(state.value);
        }

    },
    extraReducers(builder) {
        builder
            .addCase(CLEAR_ORG_ACTION, (state) => {
                state.value = null;
                setOrgInSession(state.value);
            });
    }
});

export const { setOrg } = orgSlice.actions;
export const getOrg = (state: RootState) => state.org.value;

export default orgSlice.reducer;
