import React from 'react';
import {Link, useParams} from 'react-router-dom';

import {Card, Descriptions, DescriptionsProps} from 'antd';

import type {Team, Zone} from 'types';
import {map} from 'utils';
import Object from 'components/Object';
import MainContent from 'components/layout/MainContent';

import {useTeam} from 'hooks/useTeams';
import {useZones} from 'hooks/useZones';

function items(team: Team, zone: Zone) {
    const items: DescriptionsProps['items'] = [
        {
            key: 'name',
            label: 'Name',
            children: team.name,
            span: 4
        },
        {
            key: 'zone',
            label: 'Zone',
            children: <Link to={`/zones/${zone?.id}`}>{zone?.name}</Link>,
            span: 4
        }
    ];

    return items;
}


const TeamDetailPage = () => {
    let { teamId } = useParams();

    const zones = map(useZones());
    const team = useTeam(teamId);

    if (!team) {
        return null;
    }

    const zone = zones[team.zone.id];

    return (
        <MainContent>
            <Card>
                <Descriptions
                    title="Team Info"
                    items={items(team, zone)}
                    column={4}
                />
            </Card>
            <Object object={team}/>
        </MainContent>
    );
}

export default TeamDetailPage;
