import * as d3 from 'd3';
import {Typography} from 'antd';
import type { Graph } from './GraphComponent';

const Legend = (props: {
    node: d3.HierarchyCircularNode<Graph>
}) => {
    return (
        <div style={{position: 'absolute', top: 0, left: 0}}>
            <Typography.Title level={2} style={{margin:0, fontSize:'1rem'}}>
                {props.node.data.obj.name}
            </Typography.Title>
        </div>
    );
}

export default Legend;
