import {Drawer} from 'antd';
import type {Role, Person} from 'types';
import RoleDetails from './RoleDetails';

const RoleDrawer = (props: {
    role: Role|undefined,
    user: Person|undefined,
    onClose: () => void,
}) => {
    return (
        <Drawer title={props.role?.name} onClose={props.onClose} open={!!props.role}>
            <RoleDetails role={props.role} user={props.user} ></RoleDetails>
        </Drawer>
    );
}

export default RoleDrawer;
