import type {Keyed, Role, RoleAssignment, Person, Organization} from 'types';

export function isString(s: any) {
    return (typeof s === 'string' || s instanceof String);
}

export function initials(name: string) {
    const words = name.split(' ');
    if (words.length >= 2) {
        return `${words[0][0].toUpperCase()}${words[1][0].toUpperCase()}`;
    }
    if (words.length === 1) {
        return `${words[0][0].toUpperCase()}`;
    }
    return '?'
}

export function trimSlashes(value: string|undefined) {
    return value !== undefined ? value.replace(/^\/|\/$/g, '') : undefined;
}

// https://stackoverflow.com/a/38552302
export function parseJwt (token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function userTypeDisplay(userType: Person['userType']) {
    switch (userType) {
        case 'read_only': return 'Viewer';
        case 'admin': return 'Administrator';
        case 'regular': return 'Contributor';
    }
    const value = userType?.toString().toUpperCase();
    return value ? value : 'Unknown';
}

export function map_<T>(objects: T[]) {
    const map = {} as Record<string, T>;
    for (const object of objects) {
        map[(object as {_id: string})._id] = object;
    }
    return map;
}

export function map<T>(objects: T[]) {
    const map = {} as Record<string, T>;
    for (const object of objects) {
        map[(object as {id: string}).id] = object;
    }
    return map;
}

export function selectOptions_(objects: {_id: string, name: string}[]) {
    return objects.map(obj => {
        return {label: obj.name, value: obj._id};
    });
}

export function selectOptions(objects: {id: string, name: string}[]) {
    return objects.map(obj => {
        return {label: obj.name, value: obj.id};
    });
}

export function statusColor(status: string) {
    if (status === 'archived') {
        return 'volcano';
    }
    if (status === 'draft') {
        return 'geekblue';
    }
    if (status === 'active') {
        return 'cyan';
    }
    return 'green';
}

export function getErrorMessage(e: unknown) {
    if (e instanceof Response) {
        return 'Unknown network error.';
    }
    if ((e as {message: string}).message) {
        return (e as {message: string}).message;
    }
    return (e as any).toString();
}

export function nameSort(a: {name: string}, b: {name: string}){
    let x = a.name.toLowerCase();
    let y = b.name.toLowerCase();
    if (x < y) {
        return -1;
    }
    if (x > y) {
        return 1;
    }
    return 0;
}

export function personIsAdmin(person: Person|null|undefined) {
    return person?.userType && ['admin', 'system'].includes(person.userType);
}

export function asDataSource_<T extends {_id: string}>(objs: {_id: string}[]) {
    return (objs as Keyed<T>[]).map(row => {
        return Object.assign({}, row, {key: row._id});
    })
}

export function asDataSource<T extends {id: string}>(objs: {id: string}[]) {
    return (objs as Keyed<T>[]).map(row => {
        return Object.assign({}, row, {key: row.id});
    })
}

export function roleUser(role: Role, assignments: RoleAssignment[], users: Record<string, Person>) {
    for (const assignment of assignments) {
        if (assignment.role.id === role.id && assignment.assignee?.id) {
            return users[assignment.assignee.id];
        }
    }
    return undefined;
}

export function getOrgFromSession(): Organization|null {
    try {
        const value = sessionStorage.getItem('org');
        if (value) {
            return JSON.parse(value) as Organization;
        }
    } catch (e) {
        console.log(e);
    }
    return null
}

export function setOrgInSession(org: Organization|null) {
    if (org) {
        sessionStorage.setItem('org', JSON.stringify(org));
    } else {
        sessionStorage.setItem('org', '')
    }
}

export function titleCase(str: string) {
    return str.replace(
        /\w\S*/g,
        text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
}

export function getFirstQueryParam(name: string) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
}

export function baseUrl() {
    return window.location.origin;
}

export function isDefined(value: any): boolean {
    return (typeof value !== 'undefined');
}
