import {useEffect, useState} from 'react';
import {App, Flex, Typography, Row, Col, Layout, theme} from 'antd';

import type {Organization} from 'types';
import {getErrorMessage} from 'utils';

import {organizations} from 'services/orgs';
import {setOrg} from 'features/orgSlice';

import {useAppDispatch} from 'store';
import OrgCard from './OrgCard';

import styles from './SelectOrgPage.module.scss';

import ThemeSwitcher from '../../header/ThemeSwitcher';
import Profile from '../../header/Profile';

import {useThemeMode} from 'hooks/useThemeMode';
import logo from 'assets/logo-light.png';
import logoDark from 'assets/logo-dark.png';

const SelectOrgPage = () => {
    const {message} = App.useApp();
    const [orgs, setOrgs] = useState<Organization[]>();

    const dispatch = useAppDispatch();

    const themeMode = useThemeMode();
    const src = themeMode === 'light' ? logoDark : logo;
    const {token: {colorBgContainer}} = theme.useToken();

    useEffect(() => {
        organizations()
            .then(setOrgs)
            .catch(e => message.error(getErrorMessage(e)))
    }, [message]);

    function onClick(org: Organization) {
        dispatch(setOrg(org))
    }

    return (
        <>
        <Layout style={{background: colorBgContainer, minHeight: '100vh'}}>
            <div className={styles.layout}>
                <header className={'v-org-header'}>
                    <div className='logo'>
                        <img src={src} alt={'Viggo'} />

                    </div>
                    <Flex className='org-h-end'>
                        <ThemeSwitcher />
                        <Profile />
                    </Flex>
                </header>
                <div className='v-org-wrap'>
                    <Typography.Title level={2}>Select an Organization</Typography.Title>
                    <Row gutter={24} justify="center">
                        {orgs?.map(org => (
                            <Col
                                key={org.id}
                                xs={12}
                                sm={12}
                                md={6}
                                lg={5}
                                xl={5}
                            >
                                <OrgCard org={org} onClick={onClick}/>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </Layout>
        </>
    );
}

export default SelectOrgPage;
