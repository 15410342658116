import React, {useState} from 'react';
import {useElementSize} from '@custom-react-hooks/use-element-size';

import type {Zone, Team, Role} from 'types';

import {useZones} from 'hooks/useZones';
import {useTeams} from 'hooks/useTeams';
import {useRoles} from 'hooks/useRoles';

import GraphComponent, {type Graph} from './GraphComponent';
import RoleDrawer from './RoleDrawer';
import NodeActions, {type NodeAction} from './NodeActions';

type GraphWithParent = Graph & { parentId: string | null, children: GraphWithParent[] }


function treeify(zones: Zone[], teams: Team[], roles: Role[]) {
    const graph: Record<string, GraphWithParent> = {}

    for (const zone of zones) {
        graph[zone.id] = {
            obj: zone,
            children: [],
            parentId: null,
            value: 2
        }
    }

    for (const zone of zones) {
        if (zone.parentZone?.id) {
            graph[zone.parentZone?.id].children.push(graph[zone.id]);
            graph[zone.id].parentId = zone.parentZone?.id || null;
        }
    }

    for (const team of teams) {
        graph[team.id] = {
            obj: team,
            children: [],
            parentId: null,
            value: 2
        }
    }

    for (const team of teams) {
        if (team.zone?.id) {
            graph[team.zone?.id].children.push(graph[team.id]);
            graph[team.id].parentId = team.zone?.id || null;
        }
    }

    let top: Graph|null = null;
    for (const zone of zones) {
        if (graph[zone.id].parentId === null) {
            top = graph[zone.id];
        }
    }
    return top as Graph;
}

const ZoneChartPage = () => {
    const [setRef, size] = useElementSize();
    const [roleData, setRoleData] = useState<Graph>();

    const [action, setAction] = useState<NodeAction>();

    const zones = useZones();
    const teams = useTeams();
    const roles = useRoles();

    if (zones.length === 0 || teams.length === 0) {  // roles.length === 0
        return <div>No data yet. [{zones.length}:{teams.length}]</div>;
    }

    const data = treeify(zones, teams, roles);
    return (
        <>
            <div ref={setRef} style={{height: '100%', width: '100%', padding:0, margin:0}}>
                {size.width && size.height && data ?
                    <GraphComponent
                        data={data}
                        width={size.width}
                        height={size.height - 20}
                        onSelectRole={setRoleData}
                        onAction={(action, node) => setAction({action, node})}
                        // onZoom={() => setAction(undefined)}
                    /> :
                    null
                }
            </div>
            <div style={{position:'absolute', bottom:0, left:0}}>{size.width}x{size.height}</div>
            <RoleDrawer
                role={roleData?.obj as Role|undefined}
                user={roleData?.user}
                onClose={() => setRoleData(undefined)}
            />
            <NodeActions action={action} onClose={() => setAction(undefined)} />
        </>
    );
}

export default ZoneChartPage;
