import React, {useCallback, useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import {Dropdown, MenuProps} from 'antd';

import type {Role, Team, Zone, Person, Organization} from 'types';
import AnimatedText from './AnimatedText';
import TeamNode, {zoneItems} from './TeamNode';
import RoleNode from './RoleNode';
import Legend from './Legend';
import ZoneNode from './ZoneNode';

export type Graph = {
    obj: Team | Role | Zone | Organization,
    value?: number,
    children: Graph[],
    picture?: string | null | undefined,
    user?: Person
}

function labelStyle(focus: d3.HierarchyCircularNode<Graph>, node: d3.HierarchyCircularNode<Graph>) {
    const display = node.parent === focus ? 'inline' : 'none';
    return {
        display,
        fontWeight: 'bold'
    }
}

const GraphComponent = (props: {
    data: Graph,
    width: number,
    height: number,
    onSelectRole?: (roleData: Graph | undefined) => void;
    onAction?: (action: string, node: Graph) => void;
    onZoom?: () => void;
}) => {
    const ref = useRef<SVGSVGElement>(null);
    const [zoomView, setZoomView] = useState<d3.ZoomView>([0, 0, 0]);
    const [focus, setFocus] = useState<d3.HierarchyCircularNode<Graph>>()
    const [root, setRoot] = useState<d3.HierarchyCircularNode<Graph>>()
    const {onZoom} = props;

    const zoom = useCallback((node: d3.HierarchyCircularNode<Graph>) => {
        setFocus(node);
        setZoomView([node.x, node.y, node.r * 2]);
        onZoom?.();
    }, [onZoom]);

    useEffect(() => {
        const hierarchy = d3.hierarchy<Graph>(props.data)
            .sum(d => {
                return d.value || 0
            })
            .sort((a, b) => {
                return (b.value || 0) - (a.value || 0);
            });

        // Compute the layout.
        const pack = (_: Graph) => d3.pack<Graph>()
            .size([props.width, props.height])
            .padding(8)
            (hierarchy);

        const root = pack(props.data);
        setRoot(root);

        zoom(root);
    }, [props.width, props.height, props.data, zoom]);

    if (!root || !focus) {
        return null;
    }

    function onClick(node: d3.HierarchyCircularNode<Graph>) {
        if (node.data.obj.type !== 'role') {
            zoom(node);
            props.onSelectRole?.(undefined);
        }
    }

    function onDoubleClick(node: d3.HierarchyCircularNode<Graph>) {
        if (node.data.obj.type === 'role') {
            props.onSelectRole?.(node.data);
        }
    }

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        e.domEvent.stopPropagation();
        props.onAction?.(e.key, focus.data);
    };

    const k = Math.min(props.width, props.height) / zoomView[2];

    let radius = 0;
    for (const node of focus.descendants()) {
        if (node.r && (radius === 0 || node.r * k < radius)) {
            radius = node.r * k;
        }
    }

    return (
        <>
            <Dropdown menu={{items: zoneItems, onClick: handleMenuClick}} trigger={['contextMenu']}>
                <svg
                    viewBox={`-${props.width / 2} -${props.height / 2} ${props.width} ${props.height}`}
                    width={props.width}
                    height={props.height}

                    style={{backgroundColor: 'transparent', height: 'auto', overflow: 'hidden'}}
                    ref={ref}
                    onClick={event => {
                        event.preventDefault();
                        onClick(focus?.parent ? focus.parent : root);
                    }}
                >
                    <filter x="0" y="0" width="1" height="1" id="solid">
                        <feFlood floodColor="white"></feFlood>
                        <feComposite in="SourceGraphic"></feComposite>
                    </filter>

                    <g>
                        {root
                            .descendants()
                            .slice(1)
                            .filter(node => node.data.obj.type === 'zone')
                            .map((node, index) => (
                                <ZoneNode
                                    key={`z-${index}`}
                                    node={node}
                                    cx={(node.x - focus.x) * k}
                                    cy={(node.y - focus.y) * k}
                                    r={node.r * k}
                                    onClick={onClick}
                                    onAction={(action, node) => props.onAction?.(action, node.data)}
                                />
                            ))
                        }
                    </g>
                    <g>
                        {root
                            .descendants()
                            .slice(1)
                            .filter(node => node.data.obj.type === 'team')
                            .map((node, index) => (
                                <TeamNode
                                    key={`t-${index}`}
                                    node={node}
                                    cx={(node.x - focus.x) * k}
                                    cy={(node.y - focus.y) * k}
                                    r={node.r * k}
                                    onClick={onClick}
                                    onAction={(action, node) => props.onAction?.(action, node.data)}
                                />
                            ))
                        }
                    </g>
                    <g>
                        {root
                            .descendants()
                            .slice(1)
                            .filter(node => node.data.obj.type === 'role')
                            .map((node, index) => (
                                <RoleNode
                                    key={`r-${index}`}
                                    node={node}
                                    cx={(node.x - focus.x) * k}
                                    cy={(node.y - focus.y) * k}
                                    r={node.r * k}
                                    onDoubleClick={onDoubleClick}
                                    onAction={(action, node) => props.onAction?.(action, node.data)}
                                />
                            ))
                        }
                    </g>
                    <g>
                        {root
                            .descendants()
                            .slice(1)
                            .map((node, index) => (
                                <AnimatedText
                                    key={`l-${index}`}
                                    textAnchor={'middle'}
                                    x={(node.x - focus.x) * k}
                                    y={(node.y - focus.y) * k - (node.r * k + 4)}
                                    style={labelStyle(focus, node)}
                                >
                                    {node.data.obj.name}
                                </AnimatedText>
                            ))
                        }
                    </g>
                </svg>
            </Dropdown>
            <Legend node={focus}/>
        </>
    );
};

export default GraphComponent;
