import {Form, Select} from 'antd';

const SingleSelectFieldStep = (props: {
    title: string,
    required: boolean
    value: string,
    setValue: (value: string) => void,
    options: string[]
}) => {
    const [form] = Form.useForm<{ value: string }>();
    Form.useWatch(values => {
        if (values.value !== undefined) {
            props.setValue(values.value);
        }
    }, form);

    const options = props.options.map(option => {
        return {label: option, value: option};
    });

    return (
        <Form
            name="singleSelectFieldStep"
            form={form}
            style={{maxWidth: 600}}
            initialValues={{value: props.value}}
            layout="vertical"
            autoComplete="off"
        >
            <Form.Item<{ value: string }>
                label={props.title}
                name="value"
                rules={[{required: props.required, message: 'Select a value.'}]}
            >
                <Select options={options} />
            </Form.Item>
        </Form>)
}

export default SingleSelectFieldStep;
