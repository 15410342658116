import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';

import type {Agreement, SliceStatus, PlatformError} from 'types';
import {RootState} from 'store';

import apiClient from 'services/apiClient';

type AgreementsState = {
    entities: Agreement[],
    status: SliceStatus,
    error: string,
};

export const fetchAgreements = createAsyncThunk<Agreement[]>('agreements/fetch', async () => {
    const res = await apiClient.get('/agreements');
    return (await res.json()) as Agreement[];
});

export const deleteAgreement = createAsyncThunk<Agreement, string, {
    rejectValue: PlatformError,
}>('agreement/delete', async (agreementId, thunkAPI) => {
    try {
        const res = await apiClient.delete(`/agreements/${agreementId}`);
        return (await res.json()) as Agreement;
    } catch (e) {
        return thunkAPI.rejectWithValue((await (e as Response).json()) as PlatformError);
    }
});

export const createAgreement = createAsyncThunk<Agreement, Agreement, {
    rejectValue: PlatformError,
}>('agreement/create', async (agreement, thunkAPI) => {
    try {
        const res = await apiClient.post('/agreements', agreement);
        return (await res.json()) as Agreement;
    } catch (e) {
        return thunkAPI.rejectWithValue(e as PlatformError);
    }
});

// noinspection JSUnusedGlobalSymbols
const agreementSlice = createSlice({
    name: 'agreements',
    initialState: {
        entities: [],
        status: 'idle',
        error: ''
    } satisfies AgreementsState as AgreementsState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAgreements.pending, (state: AgreementsState) => {
                state.status = 'pending';
            })
            .addCase(fetchAgreements.fulfilled, (state: AgreementsState, action: PayloadAction<Agreement[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as Agreement[];
            })
            .addCase(fetchAgreements.rejected, (state: AgreementsState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.log(action);
            })
            .addCase(createAgreement.fulfilled, (state: AgreementsState, action: PayloadAction<Agreement|PlatformError>) => {
                state.entities.push(action.payload as Agreement);
            })
            .addCase(deleteAgreement.fulfilled, (state:AgreementsState, action) => {
                state.entities = state.entities.filter((agreement: Agreement) => agreement._id !== action.payload._id);
            })
    }
});

export const selectAgreements = (state: RootState) => state.agreements.entities;
export const selectAgreementStatus = (state: RootState) => state.agreements.status;

export default agreementSlice.reducer;
