import {App, Card, Flex, Typography} from 'antd';

import {useCurrentUser} from 'hooks/useCurrentUser';

import CreateButton from 'components/steps/_common/CreateButton';
import CreateZoneSteps from 'components/steps/zones/CreateZoneSteps';

const HomePage = () => {
    const {message} = App.useApp();

    const currentUser = useCurrentUser();
    if (!currentUser) {
        return null;
    }

    async function onCreateZone() {
        return message.success('Your zone was created successfully.');
    }

    return (
        <Flex vertical gap={'8px'}>
            <Card>
                <Typography.Title level={2}>I want to ...</Typography.Title>
                <Flex gap={'8px'}>
                    <CreateButton
                        title={'Create a zone'}
                        onSuccess={onCreateZone}
                    >
                        {(onSuccess, onCancel) => (
                            <CreateZoneSteps onSuccess={onSuccess} onCancel={onCancel} />
                        )}
                    </CreateButton>

                </Flex>
            </Card>
        </Flex>
    );
}

export default HomePage;
