import React from 'react';
import {Select} from 'antd';

import {selectOptions} from 'utils';
import {usePeople} from 'hooks/usePeople';

const PersonStep = (props: {
    title: string,
    userId: string,
    setUserId: (userId: string) => void;
}) => {
    const people = usePeople();
    const options = selectOptions(people);

    return (
        <label className={'viggo-required'}>
            {props.title}
            <Select
                style={{width: '100%', marginTop: '8px'}}  // FIXME padding should come from the label
                placeholder="Please select"
                defaultValue={props.userId}
                onChange={props.setUserId}
                options={options}
            />
        </label>
    )
}

export default PersonStep;
