import {useEffect} from 'react';

import {useAppDispatch, useAppSelector} from 'store';
import {fetchTeams, selectTeams, selectTeamStatus} from 'features/teamSlice';

import type {Team} from 'types';
import {nameSort} from 'utils';

export function useTeams(): Team[] {
    const dispatch = useAppDispatch();
    const teams = useAppSelector(selectTeams);
    const teamStatus = useAppSelector(selectTeamStatus);

    useEffect(() => {
        if (teamStatus === 'idle') {
            dispatch(fetchTeams() as any);
        }
    }, [teamStatus, dispatch]);

    return [...teams].sort(nameSort);
}

export function useTeam(teamId: string|undefined) {
    const teams = useTeams();
    return teams.find(team => team.id === teamId);
}
