import {Form, Select} from 'antd';
import {ObjectRef} from 'types';

import {useObjects} from 'hooks/useObjects';
import {isDefined} from 'utils';

export function buildObjectOptions(objs: {[p: string]: {id: string, name: string}[]}) {
    const options: {label: string, value: string}[] = [];
    for (const prop in objs) {
        if (Object.prototype.hasOwnProperty.call(objs, prop)) {
            if (isDefined(objs[prop])) {
                objs[prop].forEach(obj => {
                    options.push({label: obj.name, value: JSON.stringify({id: obj.id, type: prop})});
                });
            }
        }
    }
    return options;
}

function initialValue(ref: ObjectRef|undefined) {
    if (ref) {
        return JSON.stringify(ref);
    }
    return undefined;
}

const OneToOneFieldStep = (props: {
    title: string,
    required: boolean
    objectRef: ObjectRef|undefined,
    setObjectRef: (value: ObjectRef) => void,
    allowed: ObjectRef['type'][]
}) => {
    const objs = useObjects(props.allowed);

    const [form] = Form.useForm<{ value: string }>();
    Form.useWatch(values => {
        if (values.value !== undefined) {
            props.setObjectRef(JSON.parse(values.value));
        }
    }, form);

    return (
        <Form
            name="textFieldStep"
            form={form}
            style={{maxWidth: 600}}
            initialValues={{value: initialValue(props.objectRef)}}
            layout="vertical"
            autoComplete="off"
        >
            <Form.Item<{ value: string }>
                label={props.title}
                name={'value'}
                rules={[{required: props.required, message: 'Select a type.'}]}
            >
                <Select options={buildObjectOptions(objs)} />
            </Form.Item>
        </Form>)
}

export default OneToOneFieldStep;
