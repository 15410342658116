import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';

import type {RoleAssignment, SliceStatus, PlatformError} from 'types';
import {RootState} from 'store';

import apiClient from 'services/apiClient';

type RoleAssignmentsState = {
    entities: RoleAssignment[],
    status: SliceStatus,
    error: string,
};

export const fetchRoleAssignments = createAsyncThunk<RoleAssignment[]>('roleAssignments/fetch', async () => {
    const res = await apiClient.get('/role-assignments');
    return (await res.json()) as RoleAssignment[];
});

export const deleteRoleAssignment = createAsyncThunk<RoleAssignment, string, {
    rejectValue: PlatformError,
}>('roleAssignment/delete', async (roleAssignmentId, thunkAPI) => {
    try {
        const res = await apiClient.delete(`/role-assignments/${roleAssignmentId}`);
        return (await res.json()) as RoleAssignment;
    } catch (e) {
        return thunkAPI.rejectWithValue((await (e as Response).json()) as PlatformError);
    }
});

export const createRoleAssignment = createAsyncThunk<RoleAssignment, RoleAssignment, {
    rejectValue: PlatformError,
}>('roleAssignment/create', async (roleAssignment, thunkAPI) => {
    try {
        const res = await apiClient.post('/role-assignments', roleAssignment);
        return (await res.json()) as RoleAssignment;
    } catch (e) {
        return thunkAPI.rejectWithValue(e as PlatformError);
    }
});

// noinspection JSUnusedGlobalSymbols
const roleAssignmentSlice = createSlice({
    name: 'roleAssignments',
    initialState: {
        entities: [],
        status: 'idle',
        error: ''
    } satisfies RoleAssignmentsState as RoleAssignmentsState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchRoleAssignments.pending, (state: RoleAssignmentsState) => {
                state.status = 'pending';
            })
            .addCase(fetchRoleAssignments.fulfilled, (state: RoleAssignmentsState, action: PayloadAction<RoleAssignment[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as RoleAssignment[];
            })
            .addCase(fetchRoleAssignments.rejected, (state: RoleAssignmentsState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.log(action);
            })
            .addCase(createRoleAssignment.fulfilled, (state: RoleAssignmentsState, action: PayloadAction<RoleAssignment|PlatformError>) => {
                state.entities.push(action.payload as RoleAssignment);
            })
            .addCase(deleteRoleAssignment.fulfilled, (state:RoleAssignmentsState, action) => {
                state.entities = state.entities.filter((roleAssignment: RoleAssignment) => roleAssignment._id !== action.payload._id);
                // FIXME: have to call the fetch API again as deleting one roleAssignment also deletes sub roleAssignments.
            })
    }
});

export const selectRoleAssignments = (state: RootState) => state.roleAssignments.entities;
export const selectRoleAssignmentStatus = (state: RootState) => state.roleAssignments.status;

export default roleAssignmentSlice.reducer;
