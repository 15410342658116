import {useState} from 'react';
import {Button} from 'antd';
import {Field} from 'types';
import FieldForm from './FieldForm';
import CreateModal from '../../../components/steps/_common/CreateModal';

const AddField = (props: {
    onAdd: (field: Field) => void
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    function onFinish(field: Field) {
        setIsModalOpen(false);
        props.onAdd(field);
    }

    return (
        <>
            <Button type="link" onClick={showModal}>
                Add Field
            </Button>
            <CreateModal
                title="Add Field"
                open={isModalOpen}
                onCancel={handleCancel}
            >
                <FieldForm onFinish={onFinish} />
            </CreateModal>
        </>
    );
}

export default AddField;
