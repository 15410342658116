import {Flex, Menu, type MenuProps} from 'antd';
import {Link, UIMatch, useMatches} from 'react-router-dom';
import {
    AimOutlined,
    HomeOutlined,
    IdcardOutlined,
    UserOutlined,
    GlobalOutlined,
    SettingOutlined,
    TeamOutlined,
    ToolOutlined,
} from '@ant-design/icons';
import {trimSlashes} from 'utils';
import {useCurrentUser} from 'hooks/useCurrentUser';
import React from 'react';

const items: MenuProps['items'] = [
    {
        key: 'mainGroup',
        type: 'group',
        children: [
            {
                key: 'home',
                label: <Link to="/">Home</Link>,
                icon: <HomeOutlined/>
            },
            {
                key: 'zoneChart',
                label: <Link to="/zoneChart">Zone Chart</Link>,
                icon: <AimOutlined/>
            },
            /*
            {
                key: 'network',
                label: <Link to="/network">The Network</Link>,
                icon: <BranchesOutlined/>
            },
            {
                key: 'org',
                label: <Link to="/org">Organization</Link>,
                icon: <ApartmentOutlined/>
            },
             */
            {
                type: 'divider',
                style: {
                    marginTop: '16px',
                    marginBottom: '16px',
                }
            },
            {
                key: 'tables',
                label: 'Tables',
                type: 'group',
                children: [
                    {
                        key: 'people',
                        label: <Link to="/people">People</Link>,
                        icon: <UserOutlined/>
                    },
                    {
                        key: 'zones',
                        label: <Link to="/zones">Zones</Link>,
                        icon: <GlobalOutlined/>
                    },
                    {
                        key: 'teams',
                        label: <Link to="/teams">Teams</Link>,
                        icon: <TeamOutlined/>
                    },
                    {
                        key: 'roles',
                        label: <Link to="/roles">Roles</Link>,
                        icon: <IdcardOutlined/>
                    },
                    /*
                    {
                        key: 'goals',
                        label: <Link to="/goals">Goals</Link>,
                        icon: <TrophyOutlined/>
                    },
                    {
                        key: 'agreements',
                        label: <Link to="/agreements">Agreements</Link>,
                        icon: <LikeOutlined/>
                    },
                    {
                        key: 'users',
                        label: <Link to="/users">Users</Link>,
                        icon: <UserOutlined/>
                    },
                    {
                        key: 'templates',
                        label: 'Templates',
                        icon: <TableOutlined/>,
                        children: [
                            {
                                key: 'templates-teams',
                                label: <Link to="/templates/teams">Teams</Link>,
                            },
                            {
                                key: 'templates-users',
                                label: <Link to="/templates/users">Users</Link>,
                            },
                            {
                                key: 'templates-goals',
                                label: <Link to="/templates/goals">Goals</Link>,
                            },
                            {
                                key: 'templates-roles',
                                label: <Link to="/templates/roles">Roles</Link>,
                            },
                            {
                                key: 'templates-role-assignments',
                                label: <Link to="/templates/role-assignments">Role Assignments</Link>,
                            },
                            {
                                key: 'templates-agreements',
                                label: <Link to="/templates/agreements">Agreements</Link>,
                            },
                        ],
                    },

                     */
                ],
            },
        ]
    }
];

function defaultSelectedKey(matches: UIMatch[]) {
    const lastRoute = matches[matches.length - 1];
    if (lastRoute.pathname === '/') {
        return 'home'
    }

    return trimSlashes(lastRoute.pathname);
}

const SiderMenu = () => {
    const matches = useMatches();
    const selectedKey = defaultSelectedKey(matches);
    const currentUser = useCurrentUser();

    const menuItems = [...items];

    if (currentUser) {
        if (['admin', 'system'].includes(currentUser?.userType)) {
            const children: any[] = [
                {
                    type: 'divider',
                    style: {
                        marginTop: '16px',
                        marginBottom: '16px',
                    },
                },
                {
                    key: 'settings',
                    label: <Link to="/settings">Settings</Link>,
                    icon: <SettingOutlined/>
                },
            ];

            if (currentUser.userType === 'system') {
                children.push({
                    key: 'admin',
                    label: <Link to="/admin">Admin (System Only)</Link>,
                    icon: <ToolOutlined/>
                })
            }

            menuItems.push(
                {
                    key: 'adminGroup',
                    type: 'group',
                    children: children
                }
            );
        }
    }

    return (
        <Flex vertical style={{borderRight: 0, flexGrow:1}}>
            <Menu
                style={{borderRight: 0, flexGrow:1, display:'flex', flexDirection:'column'}}
                mode="inline"
                items={menuItems}
                defaultSelectedKeys={selectedKey ? [selectedKey] : undefined}
            />
        </Flex>
    );
}

export default SiderMenu;
