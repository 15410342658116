import {Breadcrumb, Flex, Typography} from 'antd';
import type {BreadcrumbItemType, BreadcrumbSeparatorType} from 'antd/es/breadcrumb/Breadcrumb';

import ThemeSwitcher from './ThemeSwitcher';
import Profile from './Profile';

const MainHeader = (props: {
    pageTitle: string | null | undefined;
    breadcrumbs:  Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[] | null | undefined
}) => {
    return (
        <header className={'viggo-header'}>
            <div>
                {props.pageTitle ? <Typography.Title style={{textTransform: 'capitalize'}}>{props.pageTitle}</Typography.Title> : null}
                {props.breadcrumbs ? <Breadcrumb style={{margin: '0 0 16px 0'}} items={props.breadcrumbs}/> : null}
            </div>
            <Flex align={'center'} gap={'16px'}>
                <ThemeSwitcher />
                <Profile />
            </Flex>
        </header>
    );
}

export default MainHeader;
