import React, {useState} from 'react';
import * as d3 from 'd3';
import {Dropdown, type MenuProps, Popover} from 'antd';

import {Graph} from './GraphComponent';
import AnimatedCircle from './AnimatedCircle';
import AnimatedImage from './AnimatedImage';

const items: MenuProps['items'] = [
    {
        label: 'Assign User to Role',
        key: 'assign-user-to-role',
    }
];

const RoleNode = (props: {
    node: d3.HierarchyCircularNode<Graph>,
    cx: number,
    cy: number,
    r: number,
    onClick?: (node: d3.HierarchyCircularNode<Graph>) => void,
    onDoubleClick?: (node: d3.HierarchyCircularNode<Graph>) => void,
    onAction?: (action: string, node: d3.HierarchyCircularNode<Graph>) => void;
}) => {
    const [hover, setHover] = useState(false);

    function onClick(event: React.MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        props.onClick?.(props.node);
    }

    function onDoubleClick(event: React.MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        props.onDoubleClick?.(props.node);
    }

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault();
        props.onAction?.(e.key, props.node);
    };

    return (
        <>
            <Popover open={hover} content={props.node.data.obj.name}>
                <AnimatedImage
                    href={props.node.data.user?._picture_medium}
                    x={props.cx - props.r}
                    y={props.cy - props.r}
                    height={props.r * 2}
                    width={props.r * 2}
                    onClick={onClick}
                    onDoubleClick={onDoubleClick}
                    onContextMenu={event => event.stopPropagation()}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                />
            </Popover>
            <Dropdown menu={{items, onClick: handleMenuClick}} trigger={['contextMenu']}>
                <AnimatedCircle
                    cx={props.cx}
                    cy={props.cy}
                    r={props.r}

                    fill={'#ba7663'}
                    fillOpacity={props.node.data.user ? 1 : .05}
                    stroke={'#ba7663'}
                    strokeWidth={hover ? 2 : undefined}
                    onClick={onClick}
                    onDoubleClick={onDoubleClick}
                    onContextMenu={event => event.stopPropagation()}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    style={{display: props.r < 8 || !props.node.data.user || !props.node.data.user?._picture_medium ? 'inline' : 'none'}}
                />
            </Dropdown>
        </>
    );
}

export default RoleNode;
