import React from 'react';
import {Select} from 'antd';

import {selectOptions} from 'utils';
import {useTemplates} from 'hooks/useTemplates';
import type {Template} from 'types';

const TemplateStep = (props: {
    objectType: Template['objectType']
    templateId?: string,
    setTemplate: (templateId: string) => void;
}) => {
    const templates = useTemplates(props.objectType);
    const options = selectOptions(templates);

    return (
        <label className={'viggo-required'}>
            What template do you want to use?
            <Select
                style={{width: '100%', marginTop: '8px'}}  // FIXME padding should come from the label
                placeholder="Please select"
                defaultValue={props.templateId}
                onChange={props.setTemplate}
                options={options}
            />
        </label>
    )
}

export default TemplateStep;
