import React from 'react';
import {Link} from 'react-router-dom';

import {Flex, Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';

import type {Zone} from 'types';
import {asDataSource, map} from 'utils';
import {useZones} from 'hooks/useZones';
import {useTeams} from '../../../hooks/useTeams';

function createColumns(zones: Record<string, Zone>) {
    const columns: ColumnsType = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, {id}) => <Link to={`/teams/${id}`}>{text}</Link>
        },
        {
            title: 'Parent Zone',
            key: 'zone',
            render: (_, {zone}) => {
                const parentZone = zone?.id ? zones[zone.id] : null;

                if (!parentZone) {
                    return zone.id;
                }

                return (
                    <Flex align={'center'} gap={'1rem'}>
                        <Link to={`/zones/${parentZone.id}`}>{parentZone.name}</Link>
                    </Flex>
                )
            }
        }
    ];
    return columns;
}

const TeamsListPage = () => {
    const teams = useTeams();
    const zones = map(useZones());
    return (
        <Table
            columns={createColumns(zones)}
            dataSource={asDataSource(teams)}
            pagination={false}
        />
    );
}

export default TeamsListPage;
