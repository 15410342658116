import type {ObjectType, } from 'types';
import {useAppDispatch, useAppSelector} from '../store';
import {fetchPeople, selectPeople, selectPeopleStatus} from '../features/personSlice';
import {useEffect} from 'react';
import {nameSort} from '../utils';
import {fetchZones, selectZones, selectZoneStatus} from '../features/zoneSlice';
import {fetchTeams, selectTeams, selectTeamStatus} from '../features/teamSlice';
import {fetchRoles, selectRoles, selectRoleStatus} from '../features/roleSlice';

// Yuck
export function useObjects(allowed: ObjectType[]) {
    const dispatch = useAppDispatch();

    const people = useAppSelector(selectPeople) || [];
    const peopleStatus = useAppSelector(selectPeopleStatus);

    const zones = useAppSelector(selectZones);
    const zoneStatus = useAppSelector(selectZoneStatus);

    const teams = useAppSelector(selectTeams);
    const teamStatus = useAppSelector(selectTeamStatus);

    const roles = useAppSelector(selectRoles);
    const roleStatus = useAppSelector(selectRoleStatus);

    useEffect(() => {
        if (peopleStatus === 'idle' && allowed.includes('person')) {
            dispatch(fetchPeople());
        }
    }, [peopleStatus, dispatch, allowed]);

    useEffect(() => {
        if (zoneStatus === 'idle' && allowed.includes('zone')) {
            dispatch(fetchZones() as any);
        }
    }, [zoneStatus, dispatch, allowed]);

    useEffect(() => {
        if (teamStatus === 'idle' && allowed.includes('team')) {
            dispatch(fetchTeams() as any);
        }
    }, [teamStatus, dispatch, allowed]);

    useEffect(() => {
        if (roleStatus === 'idle' && allowed.includes('role')) {
            dispatch(fetchRoles());
        }
    }, [roleStatus, dispatch, allowed]);

    return {
        person: allowed.includes('person') ? [...people].sort(nameSort) : undefined,
        zone: allowed.includes('zone') ? [...zones].sort(nameSort) : undefined,
        team: allowed.includes('team') ? [...teams].sort(nameSort) : undefined,
        role: allowed.includes('role') ? [...roles].sort(nameSort) : undefined,
    } as {[key: string]: {id: string, name: string}[]}
}
