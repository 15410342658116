import React from 'react';
import * as d3 from 'd3';
import {type MenuProps} from 'antd';

import type {Graph} from './GraphComponent';
import AnimatedCircle from './AnimatedCircle';

/*
const teamItems: MenuProps['items'] = [
    {
        label: 'Add Role',
        key: 'add-role',
    }
];
 */

export const zoneItems: MenuProps['items'] = [
    {
        label: 'Add Zone',
        key: 'add-zone',
    },
    {
        label: 'Add Team',
        key: 'add-team',
    }
];

/*
function buildMenu(node: d3.HierarchyCircularNode<Graph>) {
    return teamItems;
}
 */

const TeamNode = (props: {
    node: d3.HierarchyCircularNode<Graph>,
    cx: number,
    cy: number,
    r: number,
    onClick: (node: d3.HierarchyCircularNode<Graph>) => void,
    onAction?: (action: string, node: d3.HierarchyCircularNode<Graph>) => void
}) => {
    // const templates = map_(useTemplates('team'));
    // const template = props.node.data.obj.templateId ? templates[props.node.data.obj.templateId] : undefined;
    // const items = buildMenu(props.node);

    function onClick(event: React.MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        props.onClick(props.node)
    }

    /*
    const handleMenuClick: MenuProps['onClick'] = (e) => {
        e.domEvent.stopPropagation();
        props.onAction?.(e.key, props.node);
    };
     */

    return (
        <AnimatedCircle
            cx={props.cx}
            cy={props.cy}
            r={props.r}
            onClick={onClick}
            onContextMenu={event => event.stopPropagation()}
            stroke={'#01bd50'}
            fill={'#01bd50'}
        />
    );
}

/*
<Dropdown menu={{items, onClick: handleMenuClick}} trigger={['contextMenu']}>
</Dropdown>
 */

export default TeamNode;
