import {useState} from 'react';
import {App} from 'antd';

import {
    CreateObject,
    ErrorDetail,
    ErrorDetailDispatch, MultiSelectField, OneToManyField, OneToOneField,
    SingleSelectField,
    Template,
    Zone
} from 'types';

import {AppDispatch, useAppDispatch} from 'store';

import {createZone} from 'features/zoneSlice';

import CreateSteps, {type CreateStepProps} from 'components/steps/_common/CreateSteps';
import NameStep from 'components/steps/_common/NameStep';
import ParentZoneStep from 'components/steps/_common/ParentZoneStep';
import TemplateStep from 'components/steps/_common/TemplateStep';

import TextFieldStep from 'components/steps/_common/TextFieldStep';
import SingleSelectFieldStep from 'components/steps/_common/SingleSelectFieldStep';
import MultiSelectFieldStep from 'components/steps/_common/MultiSelectFieldStep';
import OneToOneFieldStep from 'components/steps/_common/OneToOneFieldStep';
import OneToManyFieldStep from 'components/steps/_common/OneToManyFieldStep';

import {useTemplates} from 'hooks/useTemplates';
import {map} from 'utils';

function templateSteps(
    obj: CreateObject,
    setObj: (obj: any) => void,
    template: Template|undefined,
    steps: CreateStepProps[]
) {
    if (template) {
        const newSteps = [...steps];
        for (let i = 0; i < template.fields.length; i++) {
            const field = template.fields[i];
            switch (field.type) {
                case 'text':
                    newSteps.push({
                        title: field.name,
                        content:
                            <TextFieldStep
                                title={field.name}
                                required={field.required}
                                value={obj[field.name]}
                                setValue={value => {
                                    setObj({...obj, [field.name]: value})
                                }}
                            />,
                        valid: () => !!obj[field.name]
                    });
                    break;
                case 'single-select':
                    newSteps.push({
                        title: field.name,
                        content:
                            <SingleSelectFieldStep
                                title={field.name}
                                required={field.required}
                                value={obj[field.name]}
                                setValue={value => {
                                    setObj({...obj, [field.name]: value})
                                }}
                                options={(field as SingleSelectField).options || []}
                            />,
                        valid: () => !!obj[field.name]
                    });
                    break;
                case 'multi-select':
                    newSteps.push({
                        title: field.name,
                        content:
                            <MultiSelectFieldStep
                                title={field.name}
                                required={field.required}
                                values={obj[field.name] || []}
                                setValues={values => {
                                    setObj({...obj, [field.name]: values})
                                }}
                                options={(field as MultiSelectField).options || []}
                            />,
                        valid: () =>  (obj[field.name] && obj[field.name].length > 0)
                    });
                    break;
                case 'one-to-one':
                    newSteps.push({
                        title: field.name,
                        content:
                            <OneToOneFieldStep
                                title={field.name}
                                required={field.required}
                                objectRef={obj[field.name]}
                                setObjectRef={ref => {
                                    setObj({...obj, [field.name]: ref})
                                }}
                                allowed={(field as OneToOneField).allowed || []}
                            />,
                        valid: () => (obj[field.name] && obj[field.name].id)
                    });
                    break;
                case 'one-to-many':
                    newSteps.push({
                        title: field.name,
                        content:
                            <OneToManyFieldStep
                                title={field.name}
                                required={field.required}
                                objectRefs={obj[field.name]}
                                setObjectRefs={refs => {
                                    setObj({...obj, [field.name]: refs})
                                }}
                                allowed={(field as OneToManyField).allowed || []}
                            />,
                        valid: () => (obj[field.name] && obj[field.name].length > 0)
                    });
                    break;
            }
        }
        return newSteps;
    }
    return steps;
}


const CreateZoneSteps = (props: {
    zone?: Zone,
    onSuccess: (team: Zone) => void,
    onCancel: () => void,
}) => {
    const {message} = App.useApp();
    const dispatch: AppDispatch = useAppDispatch();

    const [zone, setZone] = useState<Zone>({} as Zone);
    const templates = map(useTemplates('zone'));
    const template = zone.template?.id ? templates[zone.template.id] : undefined;

    function onCancel() {
        props.onCancel();
        setTimeout(() => {
            setZone({} as Zone);
        }, 0);
    }

    async function onCreate() {
        const newZone = {...zone} as Zone;
        if (props.zone) {
            newZone.parentZone = {id: props.zone.id, type: 'zone'};
        }

        const res = await dispatch(createZone(newZone));
        if ((res as ErrorDetailDispatch<Zone>).error) {
            return message.error((res.payload as ErrorDetail).detail);
        }
        props.onSuccess(res.payload as Zone);
    }

    const steps = [
        {
            title: 'Template',
            content:
                <TemplateStep
                    objectType={'zone'}
                    templateId={zone?.templateId || ''}
                    setTemplate={templateId => {
                        setZone({...zone, template: {id:templateId, type:'template'}});
                    }}
                />,
            valid: () => !!zone.template?.id,
        },
        {
            title: 'Name',
            content:
                <NameStep
                    title="What should this zone be called?"
                    name={zone?.name || ''}
                    setName={name => {
                        setZone({...zone, name});
                    }}
                />,
            valid: () => !!zone.name,
        }
    ];

    if (!props.zone) {
        steps.push({
            title: 'Parent Zone',
            content:
                <ParentZoneStep
                    title={'Does this zone have a parent?'}
                    parentZone={zone.parentZone?.id || ''}
                    setParentZone={(parentId: string) => {
                        setZone({
                            ...zone,
                            parentZone: {id: parentId, type: 'zone'}
                        });
                    }}
                />,
            valid: () => true
        });
    }

    return (
        <>
            <CreateSteps
                steps={templateSteps(zone, setZone, template, steps)}
                onCreate={onCreate}
                onCancel={onCancel}
            />
        </>
    )
}

export default CreateZoneSteps;
